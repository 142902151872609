var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "font-w700 mr-3 pr-3 border-right"
  }, [_vm._v("Columns")]), _c('div', {
    staticClass: "DataTableColumns"
  }, _vm._l(_vm.value, function (column, index) {
    return _c('div', {
      key: column,
      staticClass: "DataTableColumns-checkbox custom-control custom-checkbox mb-1 mr-3"
    }, [_c('input', {
      staticClass: "custom-control-input",
      attrs: {
        "id": column.code,
        "type": "checkbox",
        "name": column.code
      },
      domProps: {
        "checked": !column.hidden
      },
      on: {
        "input": function input($event) {
          return _vm.onClick(index);
        }
      }
    }), _c('label', {
      staticClass: "custom-control-label",
      attrs: {
        "for": column.code
      }
    }, [_c('small', {
      staticClass: "text-capitalize"
    }, [_vm._v(_vm._s(column.name))])])]);
  }), 0)]);
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="d-flex align-items-center">
    <div class="font-w700 mr-3 pr-3 border-right">Columns</div>
    <div class="DataTableColumns">
      <div v-for="(column, index) in value" :key="column" class="DataTableColumns-checkbox custom-control custom-checkbox mb-1 mr-3">
        <input :id="column.code" :checked="!column.hidden" type="checkbox" class="custom-control-input" :name="column.code" @input="onClick(index)" />
        <label class="custom-control-label" :for="column.code"
          ><small class="text-capitalize">{{ column.name }}</small></label
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DataTableColumns',
  props: {
    value: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      test: false
    };
  },
  methods: {
    onClick(index) {
      const updatedColumns = [...this.value];

      updatedColumns[index].hidden = !updatedColumns[index].hidden;

      this.$emit('input', updatedColumns);
    }
  }
};
</script>
<style lang="scss" scoped>
.DataTableColumns {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  &-checkbox:last-child {
    margin-right: 0 !important;
  }
}
</style>
